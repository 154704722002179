import { useRef, useState } from "react";

import { verifyPartnerAccount } from "api";
import { encode as base64_encode } from "base-64";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { getLandingPage, validateEmpty, validateMobile } from "services";

import { PartnerRegisterConfirm } from "./partner-register-confirm";
import { PartnerRegisterVerify } from "./partner-register-verify";

export function PartnerRegister() {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invites, setInvites] = useState();
  const [partner, setPartner] = useState();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState();
  const [step, setStep] = useState("verify"); // verify / confirm

  const submitRef = useRef();

  const handleInput = (event) => {
    setPhone(event?.target?.value);
    setErr(null);
  };

  const verifyPhoneNumber = async (event) => {
    event?.preventDefault();

    const submitPhone = phone.replace(/\s/g, "");

    // Check mobile number is valid
    if (!validateEmpty(submitPhone) || !validateMobile(submitPhone)) {
      setErr({
        type: "mobile",
        msg: "Please enter a valid mobile number",
      });
      return;
    }

    setPhone(submitPhone);

    setIsLoading(true);

    // Check mobile number belongs to a partner account
    const verify = await verifyPartnerAccount({
      phone: submitPhone,
      phoneVerifyCode: code,
    });

    if (verify?.result === true) {
      setPartner({
        ...verify?.partner,
        entityName: verify?.organisation?.name,
        entityLocation: `${verify?.organisation?.addressState}, ${verify?.organisation?.addressPostcode}`,
      });
      setInvites(
        verify?.userInvites.map((invite) => ({
          ...invite,
          location: `${invite?.organisation?.addressState}, ${invite?.organisation?.addressPostcode}`,
        }))
      );
      setStep("confirm");
    } else if (!!verify?.smsChallenge) {
    } else {
      setErr({
        type: "mobile",
        msg: "No pending invites or registration for the specified mobile number",
      });
    }

    setIsLoading(false);

    return verify;
  };

  const resetVerifyStep = () => {
    setStep("verify");
    setPhone("");
    setPartner(null);
    setInvites(null);
  };

  const confirmRegister = ({ partner, invites }) => {
    setIsLoading(true);

    if (!!partner || invites?.length > 0) {
      window.location.href = `${getLandingPage()}?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            isPartnerClaim: true,
            ABN: partner?.ABN,
            email: partner?.email,
            firstName: partner?.firstName,
            lastName: partner?.lastName,
            invite: invites[0],
          })
        )
      )}`;
    } else {
      setErr({
        msg: "Please select one organisation to join.",
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="content-body">
      <div className="form-container flex flex-col gap-8 tablet:flex-row">
        <div className="flex w-full flex-col justify-between gap-8 tablet:gap-0">
          <div className="flex flex-col gap-8">
            <div className="title text-[38px] font-semibold text-black">
              Accept Invitation
            </div>

            <p className="flex w-full text-sm leading-normal text-gray-600 tablet:w-[70%]">
              Your organisation has been invited to register your CyberCert CAP
              Partner Account.
            </p>

            <div className="flex flex-col gap-2">
              <div className="pb-1 text-sm leading-normal text-gray-600 tablet:w-[70%]">
                Sign in to your account
              </div>

              {step === "confirm" && (
                <div className="flex flex-row items-center gap-2">
                  <HiOutlineDeviceMobile className="text-icons h-5 w-5" />

                  <span className="font-semibold text-gray-900">{phone}</span>

                  <button
                    className="text-link text-sm font-medium"
                    onClick={resetVerifyStep}
                  >
                    Change
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {
          {
            verify: (
              <PartnerRegisterVerify
                isLoading={isLoading}
                phone={phone}
                code={code}
                setCode={setCode}
                handleInput={handleInput}
                verifyPhoneNumber={verifyPhoneNumber}
                err={err}
                submitRef={submitRef}
              />
            ),
            confirm: (
              <PartnerRegisterConfirm
                isLoading={isLoading}
                err={err}
                submitRef={submitRef}
                partner={partner}
                invites={invites}
                confirmRegister={confirmRegister}
              />
            ),
          }[step]
        }
      </div>
    </div>
  );
}
