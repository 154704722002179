import {
  ButtonWithRef,
  FlowbiteTextInput,
  MobileVerificationModal,
} from "components";
import { useState } from "react";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
  handleKeyDownSubmission,
} from "services";
import { calculateEpochTimeInSecond } from "../../services/utils/all";

export function PartnerRegisterVerify({
  phone,
  code,
  setCode,
  handleInput,
  verifyPhoneNumber,
  err,
  submitRef,
}) {
  const [showPhoneVerify, setShowPhoneVerify] = useState(false);
  const [phoneVerifyExpiredAt, setPhoneVerifyExpiredAt] = useState(0);
  const [phoneVerifyResendAllowedAt, setPhoneVerifyResendAllowedAt] =
    useState(0);
  const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);

  async function requestNewCode(event) {
    event?.preventDefault();

    const result = await verifyPhoneNumber(event);

    let smsChallenge = result?.smsChallenge?.smsChallenge;
    if (!!smsChallenge) {
      setShowPhoneVerify(true);
      setPhoneVerifyExpiredAt(
        calculateEpochTimeInSecond(smsChallenge?.codeExpiredAt)
      );
      setPhoneVerifyResendAllowedAt(
        calculateEpochTimeInSecond(smsChallenge?.codeResendAllowedAt)
      );
    }
  }

  async function verifyCode() {
    const result = await verifyPhoneNumber();

    return result?.smsChallenge;
  }

  return (
    <>
      <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
        <div className="subtitle text-[14px] leading-[21px] text-gray-600">
          To get started, enter your mobile number.
        </div>

        {!!err?.msg && (
          <div className="text-[14px] font-medium text-red-600">{err.msg}</div>
        )}

        <FlowbiteTextInput
          id="MOBILE"
          name="mobile"
          type="tel"
          icon={HiOutlineDeviceMobile}
          placeholder="Enter your mobile number"
          required
          value={phone}
          onChange={handleInput}
          onKeyPress={filterNumberAndSpaceKeyPress}
          onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
          onPaste={filterNumberAndSpacePaste}
          //   color={!!abnSearchErr ? "failure" : "gray"}
        />

        <div className="flex justify-end">
          <ButtonWithRef
            variant="small"
            ref={submitRef}
            type="submit"
            onClick={requestNewCode}
          >
            Continue
          </ButtonWithRef>
        </div>
      </div>

      <MobileVerificationModal
        phone={phone}
        isOpen={showPhoneVerify}
        isMobileConfirmed={isMobileConfirmed}
        setIsMobileConfirmed={setIsMobileConfirmed}
        handleCloseModal={() => setShowPhoneVerify(false)}
        requestTime={phoneVerifyResendAllowedAt}
        expiryTime={phoneVerifyExpiredAt}
        setRequestTime={setPhoneVerifyResendAllowedAt}
        setExpiryTime={setPhoneVerifyExpiredAt}
        verify={verifyCode}
        handleRequestNewCode={requestNewCode}
        code={code}
        setCode={setCode}
      />
    </>
  );
}
