import { useEffect, useState } from "react";

import { CodeInput } from "components";
import { Alert, Modal } from "flowbite-react";
import { CgSpinner } from "react-icons/cg";
import { HiArrowRight, HiCheck, HiCheckCircle } from "react-icons/hi";
import { calculateEpochTimeInSecond, formatSecondsToMMSS } from "services";

export function MobileVerificationModal({
  phone,
  isMobileConfirmed = false,
  setIsMobileConfirmed = () => {},
  isOpen = false,
  handleCloseModal = () => {},
  handleRequestNewCode = () => {},
  verify = () => {},
  requestTime,
  setRequestTime = () => {},
  expiryTime,
  setExpiryTime = () => {},
  code,
  setCode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTry, setIsFirstTry] = useState(true);
  const [isRequestingNewCode, setIsRequestingNewCode] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    setIsLoading(false);
    setIsFirstTry(true);
    setCode("");
    setError("");
    setIsMobileConfirmed(false);
    handleCloseModal(isMobileConfirmed);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();

    setIsLoading(true);
    setIsFirstTry(false);

    if (!/^\d{6}$/.test(code)) {
      setError("Please enter verification code");
      setIsLoading(false);
      return;
    }

    let result = await verify({
      phoneVerify: true,
      phone,
      phoneVerifyCode: code,
    });

    if (!!result?.error) {
      setError(result?.error?.message);
    } else if (result?.smsChallenge?.status === "VERIFIED") {
      setIsMobileConfirmed(true);
    }

    setIsLoading(false);
    setIsFirstTry(true);
  };

  useEffect(() => {
    if (requestTime > 0) {
      const interval = setInterval(() => {
        setRequestTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [requestTime]);

  useEffect(() => {
    if (expiryTime > 0) {
      const interval = setInterval(() => {
        setExpiryTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [expiryTime]);

  return (
    <Modal
      dismissible={true}
      show={isOpen}
      size="md"
      onClose={() => {
        handleClose();
      }}
      className="font-inter"
      data-testid="mobile-verification-modal"
    >
      <Modal.Header data-testid="mobile-verification-header">Verify Your Mobile</Modal.Header>

      <Modal.Body>
        <form
          className="flex flex-col gap-8 self-stretch"
          onSubmit={handleSubmit}
          data-testid="mobile-verification-form"
        >
          {isMobileConfirmed ? (
            <p className="text-base text-gray-500">
              All done! Your mobile is now verified.
            </p>
          ) : (
            <>
              <p className="text-base text-gray-500" data-testid="mobile-verification-message">
                We sent a code to the phone number you provided.
                <br />
                Please input the code for verification.
              </p>

              {!!error && (
                <Alert
                  color="failure"
                  icon={HiCheckCircle}
                  onDismiss={() => setError("")}
                >
                  {error}
                </Alert>
              )}

              <CodeInput
                isLoading={isLoading}
                onChange={(newCode) => setCode(newCode)}
                onSubmit={handleSubmit}
                data-testid="mobile-verification-code-input"
              />

              <div className="flex flex-col gap-2">
                {requestTime > 0 ? (
                  <p className="text-base text-gray-500">
                    Request a new code in{" "}
                    <span className="text-gray-900">
                      {formatSecondsToMMSS(requestTime)}
                    </span>
                  </p>
                ) : (
                  <button
                    className="link flex items-center gap-2"
                    disabled={isRequestingNewCode}
                    type="button"
                    onClick={handleRequestNewCode}
                  >
                    <span className="">Request a new code</span>
                    {isRequestingNewCode ? (
                      <CgSpinner className="size-4 animate-spin" />
                    ) : (
                      <HiArrowRight className="size-4" />
                    )}
                  </button>
                )}

                <p className="text-base text-gray-500">
                  Your code expires in{" "}
                  <span className="text-gray-900">
                    {formatSecondsToMMSS(expiryTime)}
                  </span>
                </p>
              </div>
            </>
          )}
        </form>

        <div className="flex flex-row gap-3 items-center pt-4">
          {!isMobileConfirmed && (
            <button
              className="btn-white w-fit gap-2 px-3 py-2 text-sm font-medium"
              disabled={isLoading}
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
          )}

          <button
            className="btn-brand w-fit gap-2 px-3 py-2 text-sm font-semibold flex items-center"
            disabled={isLoading}
            type="submit"
            onClick={isMobileConfirmed ? handleClose : handleSubmit}
            data-testid="mobile-verify-done-button"
          >
            {isLoading ? (
              <CgSpinner className="size-4 animate-spin" />
            ) : (
              <HiCheck className="size-4" />
            )}
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
