import { Stepper } from "components";
import { HiRefresh } from "react-icons/hi";

export function PartnerFormStepLayout({
  children,
  steps,
  currentStep,
  partnerState,
  handleReset = () => {},
}) {
  const stepStatuses = steps.map((_, index) => {
    if (index < currentStep) {
      return "completed";
    } else if (index === currentStep) {
      return "inprogress";
    } else {
      return "todo";
    }
  });

  return (
    <>
      <div className="flex flex-1 flex-col justify-between gap-4">
        <div className="flex flex-col gap-8">
          <span className="text-[38px] font-medium leading-tight text-black">
            Your Partner Agreement
          </span>

          <span className="font-medium text-gray-600">
            Is this your organisation?
          </span>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <span className="text-xl font-bold capitalize text-gray-900">
                {partnerState?.entityName?.value}
              </span>
              <div>
                <span className="font-bold">ABN</span>{" "}
                <span>{partnerState?.ABN?.value}</span>
              </div>
            </div>

            <div className="flex gap-1 text-sm text-gray-900">
              If not,
              <button
                className="flex items-center gap-1 font-medium text-blue-600 outline-none focus:underline enabled:hover:opacity-70"
                onClick={handleReset}
              >
                Search Again <HiRefresh />
              </button>
            </div>
          </div>
        </div>

        <span className="w-3/4 text-sm text-gray-600">
          The information you provide here will be used to prepare your
          CyberCert Partner Agreement.
        </span>
      </div>

      <div className="flex flex-1 flex-col gap-8">
        <div className="flex flex-col gap-4">
          <span className="mt-2 text-xs uppercase">
            {currentStep !== steps?.length
              ? `Section ${currentStep + 1}`
              : "Completed"}
          </span>

          <span
            className={`font-semibold ${currentStep !== steps?.length ? "text-orange-500" : "text-gray-900"}`}
            data-testid="partner-form-completion-message"
          >
            {currentStep !== steps?.length
              ? steps[currentStep]
              : "You're Done!"}
          </span>

          <Stepper steps={stepStatuses} />
        </div>

        <hr />

        {children}
      </div>
    </>
  );
}
