import React, { useEffect, useRef, useState } from "react";

export function CodeInput({
  numDigits = 6,
  numPerChunk = 3,
  isLoading = false,
  onChange = () => { },
  onSubmit = () => { },
}) {
  const [code, setCode] = useState(Array(numDigits).fill(""));

  const inputRefs = useRef([]);

  const handleInput = (event, index) => {
    const value = event.target.value;
    if (!isNaN(value) && value !== "") {
      // Add digit to code
      const newCode = [...code];
      newCode[index] = value.slice(-1);
      setCode(newCode);

      // Move focus to the next input
      if (index < code.length - 1) {
        inputRefs.current[index + 1].select();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    // Enter key should submit
    if (
      event?.key === "Enter" &&
      !(event?.metaKey || event?.ctrlKey || event?.altKey)
    ) {
      onSubmit();
    }

    // Backspace and Delete keys
    if (event.keyCode === 8 || event.keyCode === 46) {
      // If backspace key is pressed and the current input box is empty, move selection to the previous input
      if (event.keyCode === 8 && index > 0 && code[index] === "") {
        inputRefs.current[index - 1].select();
      }

      // If backspace or delete is pressed and the current input box is not empty, delete the digit
      else if (
        (event.keyCode === 8 || event.keyCode === 46) &&
        index >= 0 &&
        code[index] !== ""
      ) {
        const newCode = [...code];
        newCode[index] = "";
        // Shift remaining digits to the left
        for (let i = index; i < newCode.length - 1; i++) {
          newCode[i] = newCode[i + 1];
        }
        newCode[newCode.length - 1] = "";
        setCode(newCode);

        if (index > 0 && event.keyCode === 8) {
          inputRefs.current[index - 1].focus();
        }
      }
    }

    // Left and right arrow keys
    if (event.keyCode === 37) {
      // Left arrow key pressed, move focus to the previous input box
      event.preventDefault();
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (event.keyCode === 39) {
      // Right arrow key pressed, move focus to the next input box
      event.preventDefault();
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }

    // Stop CTRL+Z
    if (event.ctrlKey || event.metaKey) {
      // Check if Ctrl (or Command on Mac) is pressed
      if (event.key === "z") {
        // Check if Z key is pressed
        event.preventDefault(); // Prevent the default undo behavior
        // Do nothing
      }
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");

    // Must be only numbers
    if (!/[0-9\s]/.test(pasteData)) return;

    const numSpaces = 6 - index;
    const codeArray = pasteData
      .replace(/\s/g, "")
      .split("")
      .filter((char) => !isNaN(char))
      .slice(0, numSpaces);
    const newCode = [...code];
    codeArray.forEach((char, i) => {
      newCode[i + index] = char;
    });
    inputRefs?.current?.[codeArray?.length + index - 1]?.select();
    setCode(newCode);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    event?.target?.setSelectionRange(
      event?.target?.value?.length,
      event?.target?.value?.length
    );
  };

  // Function to split the code into chunks
  const generateChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Split the code into chunks
  const chunks = generateChunks(code, numPerChunk);

  useEffect(() => {
    onChange(code.join(""));
  }, [code, onChange]);

  return (
    <div className="flex items-center gap-4 self-stretch">
      {chunks.map((chunk, chunkIndex) => (
        <React.Fragment key={`Chunk ${chunkIndex}`}>
          {chunk.map((digit, index) => (
            <input
              data-testid={`mobile-verification-code-input-input-${chunkIndex * numPerChunk + index}`}
              key={chunkIndex * numPerChunk + index}
              className="h-17.5 w-full appearance-none rounded-2 border-none p-3 text-center text-2xl font-medium caret-transparent outline outline-1 outline-gray-300 selection:bg-transparent focus:outline-gray-900 focus:ring-0 disabled:bg-gray-100"
              disabled={isLoading}
              ref={(el) =>
                (inputRefs.current[chunkIndex * numPerChunk + index] = el)
              }
              type="text"
              value={digit}
              onChange={(event) =>
                handleInput(event, chunkIndex * numPerChunk + index)
              }
              onKeyDown={(event) =>
                handleKeyDown(event, chunkIndex * numPerChunk + index)
              }
              onPaste={(event) =>
                handlePaste(event, chunkIndex * numPerChunk + index)
              }
              onSelect={handleSelect}
            />
          ))}
          {chunkIndex < chunks.length - 1 && (
            <div className="h-px w-3 flex-shrink-0 bg-gray-400"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
